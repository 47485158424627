import styled from 'styled-components'

const Container = styled.div`
  width: 100%;

  .intro {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 10rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    .background {
      position: relative;
      background-size: cover;
      background-position: center;
      flex: 1 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: ${({ theme }) => theme.spacingL};
      border-bottom-right-radius: 35rem;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.2);
        border-bottom-right-radius: 35rem;
      }

      .title {
        font-size: 7rem;
        line-height: 8rem;
        text-align: center;
        z-index: 1;
      }
      a {
        z-index: 1;
      }
    }

    .contact-info {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: ${({ theme }) => theme.spacingS};
      padding: ${({ theme }) => theme.spacingXS};

      svg {
        width: 3rem;
      }

      span {
        font-size: 2.5rem;

        a:not(:last-child) {
          margin-right: ${({ theme }) => theme.spacingS};
        }
      }
    }
  }

  .marcacao {
    padding: ${({ theme }) =>
      `${theme.spacingM} ${theme.spacingM} ${theme.spacingXL}`};

    .title {
      text-align: center;
    }
  }

  .marcacao {
    .text {
      max-width: 60rem;
      margin: ${({ theme }) => theme.spacingM} auto 0 auto;
      text-align: center;
    }

    form {
      margin: ${({ theme }) => theme.spacingM} auto 0 auto;
      max-width: 60rem;
      text-align: center;
    }
  }

  .services {
    padding: ${({ theme }) => `${theme.spacingXL} ${theme.spacingM}`};

    .title {
      color: ${({ theme }) => theme.gold};
      text-align: center;
    }

    .panels {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: ${({ theme }) => theme.spacingM};
    }

    .image {
      position: relative;
      width: 50rem;
      height: 35rem;
      background-size: cover;
      background-position: center;
      margin: ${({ theme }) => theme.spacingXXS};
      overflow: hidden;

      .name {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.5rem;
        text-transform: uppercase;
        border-top-left-radius: 70%;
        border-top-right-radius: 70%;
        transform: translateY(101%);
        transition: transform 0.25s ease;
      }

      &:hover .name {
        transform: translateY(0);
      }
    }
  }

  .map {
    width: 100%;
    height: 40vh;

    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  @media screen and (max-width: 1000px) {
    padding-top: ${({ theme }) => theme.spacingM};

    .intro {
      padding-top: 10vw;
      min-height: 70vh;
    }

    .marcacao {
      .title {
        line-height: 1;
        margin-top: 10vw;
      }
    }
  }

  @media screen and (max-width: 850px) {
    .intro {
      border: none;

      .contact-info {
        flex-direction: column;
        gap: ${({ theme }) => theme.spacingXXXS};
        padding-top: ${({ theme }) => theme.spacingM};

        span {
          display: flex;
          flex-direction: column;
          align-items: center;

          a {
            margin-right: 0 !important;
          }
        }
      }
    }

    .services {
      padding: 10vw 6vw;
      .panels {
        flex-direction: column;
      }
      .image {
        margin: ${({ theme }) => `${theme.spacingS} ${theme.spacingXXS}`};
      }
    }

    .map {
      height: 35vh;
    }
  }

  @media screen and (max-width: 450px) {
    .intro .background .title {
      font-size: 5rem;
      line-height: 5.5rem;
    }

    .map {
      height: 25vh;
    }
  }
`

export const Styled = {
  Container,
}
