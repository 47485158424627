import * as React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Title } from '../components/Title'
import { Text } from '../components/Text'
import { CTA } from '../components/CTA'
import { Form } from '../components/Form'
import PhoneIcon from '../assets/images/phone.svg'
import { Styled } from '../styles/contactos.styles'
import { Link, graphql } from 'gatsby'
import { handlePhoneClick } from '../utils/handleClick'

const ContactosPage = ({
  data: { contactosJson: content, miscJson: misc },
  location
}) => {
  return (
    <Layout location={location}>
      <SEO title="Contactos" description={content.seoDescription} />
      <Styled.Container>
        <section className="intro bg-blue">
          <div
            className="background"
            style={{ backgroundImage: `url(${content.backgroundImg})` }}
          >
            <Title text={content.mainTitle} />
            <CTA text={content.cta} url={'#marcacao'} className="desktop" />
          </div>

          <div className="contact-info">
            <Text text={content.contactTitle} />
            <PhoneIcon />
            <span className="white">
              <div>
                <a onClick={() => handlePhoneClick(`tel: ${misc.phone1.replace(/\s/g, '')}`, 'Móvel')}
                  style={{ cursor: 'pointer' }}
                >
                  {misc.phone1}
                </a>
                <small style={{ fontStyle: "italic" }}> «Chamada para a rede móvel nacional» </small>
              </div>
              <div>
                <a onClick={() => handlePhoneClick(`tel: ${misc.phone2.replace(/\s/g, '')}`, 'Fixo')}
                  style={{ cursor: 'pointer' }}
                >
                  {misc.phone2}
                </a>
                <small style={{ fontStyle: "italic" }}> «Chamada para a rede fixa nacional» </small>
              </div>
            </span>
          </div>
        </section>

        <section className="marcacao bg-blue" id="form">
          <Title text={content.formTitle} />
          <Text text={content.formSubTitle} />
          <Form />
        </section>

        <section className="services">
          <Title text={content.servicesTitle} />

          <div className="panels">
            <Link to={content.service1Url}>
              <div
                className="image"
                style={{ backgroundImage: `url(${content.service1Img})` }}
              >
                <div className="name bg-blue white">{content.service1Name}</div>
              </div>
            </Link>

            <Link to={content.service2Url}>
              <div
                className="image"
                style={{ backgroundImage: `url(${content.service2Img})` }}
              >
                <div className="name bg-gold white">{content.service2Name}</div>
              </div>
            </Link>
          </div>
        </section>

        <section className="map">
          <iframe
            src="https://snazzymaps.com/embed/356260"
            className="iframe"
          ></iframe>
        </section>
      </Styled.Container>
    </Layout>
  )
}

export default ContactosPage

export const pageQuery = graphql`
  query {
    contactosJson {
      seoDescription
      backgroundImg
      mainTitle
      cta
      contactTitle
      formTitle
      formSubTitle
      servicesTitle
      service1Img
      service1Name
      service1Url
      service2Img
      service2Name
      service2Url
    }
    miscJson {
      phone1
      phone2
    }
  }
`
